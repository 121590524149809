* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

#root{
  margin: 0;
  padding: 0;
}

html {
  --blue01: hsl(233, 90%, 16%);
  --blue02: hsl(211,35%,48%);
  --blue03: hsl(192, 59%, 51%);
  --yellow: hsl(51, 100%, 48%);
}

html, body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

/* fonts ----------------------------------
font-family: 'Montserrat', sans-serif;
font-family: 'Playfair Display', serif; 
---------------------------------------- */

/* media queries breakpoints --------------
- 1300px
- 1000px
- 800 px 
----------------------------------------- */

/* pages --------------------------------------------------------------------------------------------------------- */

.page {
  background-color: var(--blue02);
  color: white;
  min-height: 130vh;
  position: relative;
  overflow-x: hidden;
}

.page--top {
  width: 100%;
  height: 85vh;
  padding: 2rem 7rem;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  position: relative;
}

.page--top--titles {
  z-index: 3;
}

.page--top--mainTitle {
  display: inline-block;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.5;
  transition: 1.5s 0.5s;
}

.page--top--mainTitle--hidden{
  opacity: 0;
  transform: translateY(-5rem);
}

.page--top--subtitle {
  display: inline-block;
  font-size: 2.3rem;
  line-height: 1.3;
  transition: 1.5s 1s;
}

.page--top--subtitle--hidden {
  opacity: 0;
  transform: translateX(5rem);
}

.page--top--gradient {
  background: linear-gradient(90deg, hsla(233, 90%, 16%, 0.7), hsla(0, 0%, 0%, 0));
  height: 85vh;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}

.page--top--button {
  text-align: center;
  background-color: var(--blue01);
  color: white;
  padding: 0.7rem 1.3rem;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  z-index: 3;
  border: 2px solid var(--blue01);
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, font-weight 0.3s, border 0.3s;
  transition: opacity 1.5s 1.5s, transform 1.5s 1.5s;
}

.page--top--button--hidden {
  opacity: 0;
  transform: translateY(3rem);
}

.page--top--button:hover {
  background-color: hsla(0, 0%, 0%, 0);
  border: 2px solid white;
  font-weight: bold;
}

.address--link {
  cursor: pointer;
}

@media screen and (max-width: 1000px) {

  .page--top {
    height: 100vh;
    padding: 2rem 5rem;
  }

  .page--top--gradient {
    height: 100vh;
  }
  
}

@media screen and (max-width: 800px) {

  .page--top {
    height: 100vh;
    padding: 2rem 3rem;
  }

  .page--top--gradient {
    height: 100vh;
  }

  .page--top--mainTitle {
    font-size: 2.5rem;
  }
  
  .page--top--subtitle {
    font-size: 1.8rem;
  }

  .page--top--button {
    font-size: 1rem;
  }

  #pageTop01 {
    background-position-x: 25%;
  }

  #pageTop02 {
    background-position-x: 55%;
  }

  #pageTop03 {
    background-position-x: center;
  }

  #pageTop04 {
    background-position-x: center;
  }

  #pageTop05 {
    background-position-x: center;
  }

}

/* Navbar ------------------------------------------------------------------------------------------------ */

.nav {
  width: 100%;
  z-index: 10;
  background-color: white;
}

.upperNav {
  width: 100%;
  height: 2.8rem;
  background-color: var(--blue01);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  transition: 0.5s;
}

.upperNavHidden {
  height: 0;
}

.upperNav--social {
  height: auto;
  width: 1.8rem;
  transition: 0.3s;
}

.upperNav--social:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.mainNav {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 5rem;
  box-shadow: 0px 2px 3px 1px hsla(0, 0%, 0%, 0.3);
  transition: 0.3s;
}

.nav--logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.nav--logo01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  transition: 0.3s;
}

.nav--logo01 img {
  width: 100%;
  height: 100%;
}

.nav--logo02 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 2rem;
  background-color: var(--blue01);
  transition: 0.3s;
}

.nav--logo02 img {
  width: 100%;
  height: 100%;
}

.nav--links {
  position: relative;
  z-index: 7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  list-style: none;
}

.nav--links a {
  text-align: center;
  display: block;
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  padding: 2.5rem 1rem;
  transition: 0.3s;
}

.nav--links a:hover {
  color: white;
  background-color: var(--yellow);
}

.nav--menuButtonContainer{
  display: none;
}

@media screen and (max-width: 1300px){

  .mainNav {
    padding: 0rem 3rem;
  }

  .nav--logos {
    transform: scale(0.8);
  }

  .mainNav .nav--links a {
    font-size: 1.1rem;
  }


}

/* mobile navbar styles */
@media screen and (max-width: 1000px){

  .nav {
    position: fixed;
  }

  .upperNav {
    display: none;
  }

  .mainNav {
    padding: 0.5rem 0rem;
    border-bottom: 5px solid var(--blue01);
  }

  .nav--logos {
    gap: 0.5rem;
    margin-left: 3rem;
    transform: scale(1);
  }

  .nav--logo01 {
    width: 2rem;
    height: 2rem;
  }

  .nav--logo02 {
    height: 1.5rem;
    width: 7rem;
  }
  

  .nav--links {
    width: 100%;
    position: absolute;
    flex-direction: column;
    background-color: white;
    z-index: -1;
    top: 0rem;
    transform: translateY(-40rem);
    padding-top : 5rem;
    padding-bottom: 10rem;
    transition: 0.5s;
  }

  .nav--links--Open {
    transform: translateY(0rem);
  }

  .nav--links li{
    width: 100%;
  }

  .nav--links a {
    color: var(--blue01);
    font-weight: bold;
    font-size: 1.3rem;
    padding: 1.7rem 1rem;
    transition: 0.3s;
    width: 100%;
    text-align: center;
  }

  .nav--menuButtonContainer{
    width: 3rem;
    height: 3rem;
    border: 3px solid var(--blue01);
    border-radius: 0.5rem;
    margin-right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    transform: scale(0.85);
  }

  .nav--menuButton {
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    border: none;
    background-color: white;
    cursor: pointer;
  }

  .menuButton--topBar {
    background-color: var(--blue01);
    height: 3px;
    width: 100%;
    border-radius: 100vh;
    transition: 0.3s;
  }

  .menuButton--topBar--cross {
    transform-origin: left;
    transform: translateY(-0.27rem) rotateZ(45deg) scaleX(1.35);
  }

  .menuButton--middleBar {
    background-color: var(--blue01);
    height: 3px;
    width: 100%;
    border-radius: 100vh;
    transition: 0.3s;
  }

  .menuButton--middleBar--cross {
    background-color: hsla(0, 0%, 0%, 0);
  }

  .menuButton--bottomBar {
    background-color: var(--blue01);
    height: 3px;
    width: 100%;
    border-radius: 100vh;
    transition: 0.3s;
  }

  .menuButton--bottomBar--cross {
    transform-origin: left;
    transform: translateY(0.27rem) rotateZ(-45deg) scaleX(1.35);
  }

}

@media screen and (max-width: 800px){

  .nav--logos {
    margin-left: 1rem;
  }

  .nav--menuButtonContainer {
    margin-right: 1rem;
  }

}

/* styles for when mainNav is fixed (see intersection observer in Navbar component) */
@media screen and (min-width: 1000px) {
  
  .mainNavFixed {
    position: fixed;
    z-index: 10;
    width: 100%;
  }

  .mainNavFixed .nav--logo01 {
    width: 2.5rem;
    height: 2.5rem
  }

  .mainNavFixed .nav--logo02 {
    width: 7rem;
    height: 1.4rem;
  }

  .mainNavFixed .nav--links a {
    font-size: 1.1rem;
    padding: 1rem 1rem;
  }
}

/* --- */


/* Footer ------------------------------------------------------------------------------------------------ */

footer {
  width: 100%;
}

.topFooter {
  width: 100%;
  background-color: white;
  height: 10rem;
  display: flex;
  flex-direction: row;
}

.topFooter--left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
}

.topFooter--right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding-top: 2rem;
}

.topFooter p {
  font-weight: bold;
  color: var(--blue01);
  font-size: 1.3rem;
}

.odecLogo {
  width: 25rem;
  height: auto;
}

.crccLogo {
  width: 15rem;
  height: auto;
}

.mainFooter {
  position: relative;
  background-color: white;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer--logoDiv {
  width: 30%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer--logo {
  width: 40%;
  height: auto;
}

.footer--contactDiv {
  font-size: 1rem;
  width: 40%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer--contactDiv--content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: flex-start;
}

.footer--contactDiv--subDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  line-height: 1.4;
}

.footerSVGContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
}

.footerSVG {
  transform: scale(0.7);
  fill: var(--blue01);
}

.footer--navDiv {
  width: 30%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.footer--navDiv--list {
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  list-style-type: none;
  gap: 0.7rem;
}

.footer--navDiv--list a {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  transition: 0.3s;
}

.footer--navDiv--list a:hover {
  color: var(--blue02);
}

.footer--socialDiv {
  width: 12rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1.7rem;
}

.footer--socialContainer {
  height: 2.7rem;
  width: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue02);
  border-radius: 100%;
  transition: 0.3s;
}

.footer--socialContainer:hover {
  transform: translateY(-0.5rem) scale(1.1);
  cursor: pointer;
}

.footer--social {
  height: auto;
  width: 1.8rem;
  transition: 0.3s;
}

.subFooter {
  position: relative;
  font-size: 0.8rem;
  height: 3rem;
  width: 100%;
  background-color: var(--blue01);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.y_websiteLInk {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1.3rem;
  right: 1.5rem;
  gap: 1rem;
}

.y_websiteLInk p {
  font-size: 0.6rem;
}

.y_logo {
  height: 100%;
  width: auto;
}

/* media queries */
@media screen and (max-width: 1300px){
  .footer--logoDiv {
    width: 20%;
  }

  .footer--logo {
    width: 70%;
    height: auto;
  }

  .footer--contactDiv {
    width: 50%;
  }

  .footer--contactDiv--content {
    gap: 1rem;
  }


  .footer--navDiv {
    width: 30%;
  }
}

@media screen and (max-width: 1000px){

  .topFooter--right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding-top: 2rem;
    padding-right: 2rem;
  }

  .odecLogo {
    width: 23rem;
    height: auto;
  }
  
  .crccLogo {
    width: 12rem;
    height: auto;
  }

  .mainFooter {
    padding: 0rem 2rem;
  }

  .footer--logoDiv {
    display: none;
  }  

  .footer--contactDiv {
    width: 65%;
  }

  .footer--contactDiv--content {
    gap: 0.8rem;
  }

  .footer--navDiv {
    width: 35%;
  }

  .footer--navDiv--list {
    gap: 0.5rem;
  }

  .footer--socialDiv {
    gap: 1rem;
  }

}

@media screen and (max-width: 800px){

  footer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .topFooter {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .topFooter--left {
    width: 100%;
    padding: 2rem 1rem;
  }

  .topFooter--right {
    width: 100%;
    flex-direction: column;
    padding: 2rem 1rem;
    align-items: center;
  }

  .topFooter p {
    font-size: 1rem;
  }

  .odecLogo {
    width: 18rem;
    height: auto;
  }

  .crccLogo {
    width: 12rem;
    height: auto;
  }

  .mainFooter {
    flex-direction: column;
    height: auto;
  }

  .footer--contactDiv {
    width: 100%;
    
  }

  .footer--contactDiv--subDiv {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  .footerSVGContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
  }

  .contactDiv--subdiv--sub {
    width: 70%;
  }

  .footer--navDiv--list {
    display: none;
  }

  .footer--socialDiv {
    justify-content: center;
  }

  .subFooter {
    text-align: center;
    padding: 1.5rem 0rem;
    height: auto;
    gap: 0.7rem;
  }

  .y_websiteLInk {
    position: static;
  }

}

/* Home ------------------------------------------------------------------------------------------------------------- */

.home--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14rem;
}

.home--content h2 {
  font-size: 2rem !important;
}

.home--figures{
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  padding: 0rem 3rem;
  padding-bottom: 7rem;
}

.home--figures--subHeader {
  margin-top: -3rem;
  font-size: 1.2rem;
}

.figures--row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 7rem;
}

.figures--box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
}

.figures--mumber {
  font-size: 7rem;
  font-weight: bolder;
  color: white;
}

.figureSpan {
  font-family: Verdana, sans-serif;
}

.figures--text {
  font-size: 1.2rem;
  color: var(--blue01);
  font-weight: bold;
}

.figures--button {
  background-color: var(--blue01);
  color: white;
  padding: 0.7rem 1.3rem;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  border: 2px solid var(--blue01);
  transition: 0.3s;
  cursor: pointer;
  width: fit-content;
}

.figures--button:hover {
  background-color: hsla(0, 0%, 0%, 0);
  border-color: white;
  font-weight: bold;
}

.home--whyChooseUs {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 5rem 0rem;
}

.home--whyChooseUs h2 {
  color: var(--blue01);
}

.WhyChooseUs--row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem;
}

.whyChooseUs--subrow1 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0rem 2rem 0rem;
}

.whyChooseUs--subrow2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  padding: 2rem 0rem 2rem 0rem;
}

.whyChooseUs--box {
  width: 40%;
  height: 25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 3rem 2rem;
  padding-bottom: 2rem;
  color: var(--blue01);
  border-radius: 3rem 0rem 3rem 0rem;
  line-height: 1.6;
  transition: 0.3s;
}

.whyChooseUs--box:hover {
  background-color: var(--blue01);
  color: white;
}

.wcu--icon{
  width: 5rem;
  height: 5rem;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  transition: 0.3s;
}

.home--svg {
  fill: var(--blue01);
  transform: scale(1.2);
  transition: 0.3s;
}

.whyChooseUs--box:hover .wcu--icon {
  background-color: var(--blue01);
  border: 3px solid var(--yellow);
}

.whyChooseUs--box:hover .home--svg {
  fill: var(--yellow);
}

.home--letsMeet {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  background-color: var(--blue01);
  color: white;
  padding: 1rem 7rem;
}

.home--letsMeet p {
  width: 70%;
  font-size: 1.3rem;
}

.letsMeet--button {
  display: block;
  background-color: white;
  color: var(--blue01);
  padding: 0.7rem 1.3rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.3rem;
  border: 2px solid white;
  transition-property: background-color, font-weight, border, opacity, transform;
  transition-duration: 0.3s, 0.3s, 0.3s, 1.5s, 1.5s;
  transition-delay: 0s, 0s, 0s, 0.3s, 0.3s;
  cursor: pointer;
  width: fit-content;
}

.letsMeet--button--hidden {
  opacity: 0;
  transform: translateX(5rem);
}

.letsMeet--button:hover {
  color: var(--yellow);
  background-color: hsla(0, 0%, 0%, 0);
  border-color: var(--yellow);
}

.letsMeetText {
  transition: 1.5s;
}

.letsMeetText--hidden {
  opacity: 0;
  transform: translateX(-5rem);
}

.home--testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 7rem 5rem;
  width: 100%;
}

.home--testimonials > p {
  color: var(--blue01);
  font-size: 1.2rem;
}

.testimonials--container {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 50rem;
  border-radius: 0.5rem;
  padding: 3rem 3rem;
}

.testimonial {
  font-size: 1.5rem;;
  color:hsl(0, 0%, 90%);
}

.testimonialAuthor {
  font-size: 1.2rem;
  font-weight: bold;
}

/* media queries */

@media screen and (max-width: 1300px){

  .figures--row {
    gap: 5rem;
  }
  
  .figures--text {
    font-size: 1.2rem;
  }

  .figures--box {
    width: 12rem;
  }

  .WhyChooseUs--row {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }
  
  .whyChooseUs--subrow1 {
    width: 70%;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    padding: 0;
  }
  
  .whyChooseUs--subrow2 {
    width: 70%;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    padding: 0;
  }
  
  .whyChooseUs--box {
    width: 45%;
    height: 25rem;
  }
  
}

@media screen and (max-width: 1000px){

  .figures--row {
    gap: 3rem;
  }
  
  .figures--box {
    width: 12rem;
  }
  
  .figures--mumber {
    font-size: 5rem;
  }

  .whyChooseUs--subrow1 {
    width: 80%;
  }
  
  .whyChooseUs--subrow2 {
    width: 80%;
  }

}

@media screen and (max-width: 800px){

  .home--content {
    padding-top: 7rem;
  }

  
  .home--figures {
    padding: 0rem 1rem;
    padding-bottom: 5rem;
  }

  .figures--row {
    flex-direction: column;
  }

  .home--whyChooseUs {
    text-align: center;
    align-items: center;
    height: auto;
    width: 100%;
  }

  .WhyChooseUs--row {
    width: 100%;
  }

  .whyChooseUs--subrow1 {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .whyChooseUs--subrow2 {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .whyChooseUs--box {
    height: 27rem;
    width: 17rem;
  }

  .home--letsMeet {
    padding: 2rem 1rem;
    text-align: center;
    height: auto;
    flex-direction: column;
  }

  .figures--button, .letsMeet--button {
    font-size: 1rem;
  }

}


/* Cabinet ---------------------------------------------------------------------------------------------------------- */

.cabinet--content {
  padding: 14rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10rem;
}

.cabinet--content h2 {
  font-size: 2rem;
}

.cabinet--cabinet {
  display: flex;
  flex-direction: row;
  width: 90%;
  box-shadow: 2px 2px 8px 2px hsla(0, 0%, 0%, 0.3);
  border-radius: 0.3rem;
}

.cabinet--text {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding: 3rem 3rem;
  background-color: white;
  border-radius: 0.3rem 0rem 0rem 0.3rem;
}

.cabinet--text h2 {
  color: black;
}

.cabinet--text p {
  line-height: 1.4;
  color: hsl(0, 0%, 40%);
  text-align: justify;
}

.cabinet--pictureContainer {
  padding: 2rem;
  width: 50%;
  background-color: white;
  border-radius: 0rem 0.3rem 0.3rem 0rem;
}

.cabinet--picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  background-color: white;
  background-size: cover;
  background-position-x: center;
}



.cabinet--dirigeants {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 3.5rem;
}

.dirigeants--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
}

.dirigeant {
  height: 100vh;
  background-color: white;
  width: 45%;
  border-radius: 0.3rem;
  box-shadow: 2px 2px 8px 2px hsla(0, 0%, 0%, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem;
  line-height: 1.4;
}


.arnaudDiv {
  transition: 1.5s;
}

.arnaudDiv--hidden {
  opacity: 0;
  transform: translateX(5rem);
}

.dirigeant h3 {
  color: black;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  padding: 1rem;
}

.dirigeant--picture {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 80%;
  height: 50%;
  background-color: hsl(0, 0%, 92%);
  background-size: cover;
  border-radius: 0.7rem;
}

#arnaudPicture {
  background-position: 50%;
}

.dirigeant--description {
  color:hsl(0, 0%, 40%);
  margin-top: 1rem;
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}

.cabinet--histoire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-top: 5rem;
  width: 100%;
}

.cabinet--histoire h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 5rem;
}

.histoire--timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 2rem;
}

.timeline--section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6.2rem;
}

.timeline--middleLine {
  position: absolute;
  width: 3px;
  background-color: white;
  height: 60rem
}

.histoire--cell {
  width: 30rem;
  height: 18rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  line-height: 1.6;
}

.histoire--cell--left--pic {
  justify-content: flex-end;
}

.histoire--text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.histoire--text--left {
  text-align: right;
}

.histoire--text--right {
  text-align: left;
}

.histoire--text h3 {
  width: 100%;
  font-size: 3rem;
  position: relative;
  display: flex;
  align-items: center;
}

.h--1993 {
  color: var(--blue01);
  justify-content: flex-end;
}

.h--1993::after {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: var(--blue01);
  position: absolute;
  right: -5.05rem;
  transform: rotateZ(45deg);
  border-radius: 0.3rem;
}

.h--2001 {
  color: var(--yellow);
  justify-content: flex-start;
}

.h--2001::before {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: var(--yellow);
  position: absolute;
  left: -5.05rem;
  transform: rotateZ(45deg);
  border-radius: 0.3rem;
}

.h--2023 {
  color: white;
  justify-content: flex-end;
}

.h--2023::after {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: white;
  position: absolute;
  right: -5.05rem;
  transform: rotateZ(45deg);
  border-radius: 0.3rem;
}

.histoire--text p {
  width: 100%;
  font-size: 1.2rem;
}

.pic--1993 {
  width: 80%;
  height: 100%;
  background-color: white;
  border: 5px solid var(--blue01);
  border-radius: 0rem 3rem 0rem 3rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1.5s;
}

.pic--1993--hidden {
  opacity: 0;
  transform: translateX(5rem);
}

.oldLogoPic {
  width: 100%;
  height: auto;
}

.pic--2001 {
  width: 80%;
  height: 100%;
  background: linear-gradient(45deg, red, darkblue);
  border: 5px solid var(--yellow);
  border-radius: 3rem 0rem 3rem 0rem;
  background-size: cover;
  transition: 1.5s;
}

.pic--2001--hidden {
  opacity: 0;
  transform: translateX(-5rem);
}

.pic--2023 {
  width: 80%;
  height: 100%;
  background: linear-gradient(45deg, lightgreen, magenta);
  border: 5px solid white;
  border-radius: 0rem 3rem 0rem 3rem;
  background-size: cover;
  transition: 1.5s;
}

.pic--2023--hidden {
  opacity: 0;
  transform: translateX(5rem);
}

/* media queries */

@media screen and (min-width: 1700px){
  .cabinet--text {
    font-size: 1rem;
    gap: 1.3rem;
    padding-bottom: 10rem;
  }
}

@media screen and (max-width: 1300px){


  .cabinet--dirigeants {
    width: 100%;
  }

  .dirigeant {
    height: 100vh;
    width: 47%;
    padding: 1rem 2rem;
    line-height: 1.4;
  }
  
  .dirigeant--picture {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 90%;
    background-position: center;
  }

  .histoire--cell {
    width: 23rem;
    height: 18rem;
    padding: 1rem;
    line-height: 1.4;
  }
  
  .histoire--text h3 {
    font-size: 2.7rem;
  }
  
  .h--1993::after {
    width: 2rem;
    height: 2rem;
    right: -5.05rem;
  }
  
  .h--2001::before {
    width: 2rem;
    height: 2rem;
    left: -5.05rem;
  }
  
  .h--2023::after {
    width: 2rem;
    height: 2rem;
    right: -5.05rem;
  }
  
  .histoire--text p {
    font-size: 1.1rem;
  }
  
  .pic--1993{
    width: 100%;
  }
  
  .pic--2001{
    width: 100%;
  }
  
  .pic--2023{
    width: 100%;
  }

}

@media screen and (max-width: 1000px){

  .cabinet--cabinet {
    width: 100%;
    flex-direction: column;
    height: auto;
  }

  .cabinet--text {
    width: 100%;
    font-size: 1rem;
    gap: 1.3rem;
    padding: 3rem 3rem;
    border-radius: 0.3rem 0.3rem 0rem 0rem;
  }


  .cabinet--pictureContainer {
    padding: 5rem;
    width: 100%;
    height: 50rem;
    border-radius: 0rem 0rem 0.3rem 0.3rem;
  }

  .cabinet--picture {
    width: 100%;
    height: 100%;
  }

  .cabinet--dirigeants h2{
    margin-bottom: 3rem;
  }

  .dirigeants--container {
    flex-direction: column;
    gap: 3rem;
  }

  .dirigeant {
    width: 30rem;
    height: 50rem;
  }

  .histoire--cell {
    width: 17rem;
    height: 15rem;
    padding: 0.5rem;
    line-height: 1.3;
    margin-bottom: 1.5rem;
  }
  
  .histoire--text h3 {
    font-size: 2.3rem;
  }
  
  .h--1993::after {
    width: 1.7rem;
    height: 1.7rem;
    right: -4.45rem;
  }
  
  .h--2001::before {
    width: 1.7rem;
    height: 1.7rem;
    left: -4.45rem;
  }
  
  .h--2023::after {
    width: 1.7rem;
    height: 1.7rem;
    right: -4.45rem;
  }

}

@media screen and (max-width: 800px) {
  
  .cabinet--content {
    padding: 7rem 0rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
  }

  .cabinet--text {
    padding-top: 7rem;;
    background-color: var(--blue01);
    color: white;
    border-radius: 0%;
  }

  .cabinet--text h2 {
    color: white;
    font-size: 1.5rem !important;
  }

  .cabinet--pictureContainer {
    padding: 2rem;
    background-color: var(--blue01);
    height: 30rem;
    border-radius: 0%;
  }

  .cabinet--text p {
    color: white;
  }

  .cabinet--dirigeants h2 {
    font-size: 1.5rem;
  }

  .cabinet--dirigeants h2 {
    margin-bottom: 1rem;
  }

  .dirigeant {
    width: 18rem;
    height: auto;
    padding-bottom: 3rem;
  }

  .dirigeant--picture {
    width: 100%;
    height: 20rem;
  }

  .dirigeant--description {
    line-height: 1.6;
  }

  
  .timeline--middleLine {
    top: -2rem;
    right: -1rem;
    height: 100rem;
  }

  
  
  .cabinet--histoire {
    padding-top: 5rem;
    padding-bottom: 0;
    padding-right: 3rem;
    padding-left: 1rem;
  }

  .cabinet--histoire h2 {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    margin-left: 2rem;
  }

  .histoire--timeline {
    gap: 0;
  }

  .timeline--section {
    gap: 0;
  }

  #section_1 {
    flex-direction: column;
    height: auto;
  }

  #section_2 {
    flex-direction: column-reverse;
    height: auto;
  }

  #section_3 {
    flex-direction: column;
    height: auto;
  }

  .histoire--cell {
    height: auto;
    width: 12.5rem;
  }

  .pic--1993 {
    height: 10rem;
  }

  .pic--2001 {
    height: 10rem;
  }

  .pic--2023 {
    height: 10rem;
  }

  .histoire--text--right {
    align-items: flex-end;
    text-align: right;
  }

  .h--2001 {
    justify-content: flex-end;
    text-align: right;
  }

  .h--2001::before {
    display: none;
  }
  
  .h--1993::after {
    width: 1.2rem;
    height: 1.2rem;
    right: -2rem;
  }

  .h--2001::after {
    width: 1.2rem;
    height: 1.2rem;
    right: -2rem;
  }

  .h--2023::after {
    width: 1.2rem;
    height: 1.2rem;
    right: -2rem;
  }
  
}



/* Services ------------------------------------------------------------------------------------------------------------- */

.services--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14rem 1rem;
  color: black;
  gap: 5rem;
  margin: 0;
  font-size: 1.1rem;
}

.services--grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.serviceBox {
  text-align: center;
  width: 35rem;
  height: 27rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3rem;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  padding: 3rem;
  transition: 0.4s;
}

.serviceBox:hover {
  background-color: var(--blue01);
  color: white;
}

.serviceBox:hover .service--round {
  transform: scale(1.3);
  background-color: var(--blue02);
  border-color: var(--blue01);
}

.serviceBox:hover .servicesSVG {
  fill: var(--yellow);
}

.serviceBox:hover .service--button {
  font-weight: bold;
  border: 2px solid white;
}

.serviceBox:hover .service--button--yellowBar {
  background-color: var(--yellow);
}

.service--title {
  font-size: 1.3rem;
  font-weight: bold;
}

.service--round {
  height: 10rem;
  width: 10rem;
  background-color: hsl(233, 100%, 7%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 7px solid hsl(233, 100%, 7%);
  transition: 0.4s;
}

.servicesSVG {
  fill: hsl(0, 0%, 80%);
  transform: scale(1.6);
  transition: 0.4s;
}

.service--button {
  width: 12rem;
  height: 3rem;
  font-size: 1.1rem;
  background-color: var(--blue01);
  color: white;
  border-radius: 0.3rem;
  text-align: center;
  border: 2px solid var(--blue01);
  transition: 0.4s;
  cursor: pointer;
  position: relative;
}

.service--button--yellowBar {
  position: absolute;
  height: 11.2px;
  width: 1.7px;
  background-color: hsla(0, 0%, 0%, 0);
  left: 53.1px;
  top: 16.1px;
}

.buttonType01 {
  background-color: var(--blue01);
  color: white;
  padding: 0.7rem 1.3rem;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  border: 2px solid var(--blue01);
  transition: 0.3s;
  cursor: pointer;
  background-color: var(--blue01);
  width: fit-content;
}

.buttonType01:hover {
  background-color: white;
  color: var(--blue01);
  font-weight: bold;
}

/* media queries */

@media screen and (max-width: 1215px){

  .serviceBox {
    width: 25rem;
    height: 25rem;
    padding: 2rem;
  }
}

@media screen and (max-width: 1000px){

  .serviceBoxContainer {
    width: 98%;
  }

  .serviceBox {
    width: 100%;
    height: 30rem;
    padding: 3rem;
  }
}

/* Services/subpage & Secteurs/subpages */

.subPage--header {
  background-color: var(--blue01);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 7rem;
  color: white;
  position: relative;
  z-index: 2;
}

.subPage--header--menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  width: 17rem;
  padding: 0.7rem 1.7rem;
  font-size: 1.2rem;
  background-color: var(--blue02);
  cursor: pointer;
  transition: 0.3s;
}

.subPage--header--menu--arrow--down {
  height: 0.8rem;
  width: 0.8rem;
  border-top: 3px solid white;
  border-right: 3px solid white;
  transform: rotateZ(135deg);
  transition: 0.3s;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
}

.subPage--header--menu--arrow--up {
  height: 0.8rem;
  width: 0.8rem;
  border-top: 3px solid white;
  border-right: 3px solid white;
  transform: rotateZ(-45deg);
  transition: 0.3s;
  margin-top: 0.3rem;
  margin-right: 0.3rem;
}

.subPage--header--menu:hover .subPage--header--menu--arrow--down{
  transform: rotateZ(135deg) scale(1.3);

}

.subPage--header--menu ul {
  position: absolute;
  width: 17rem;
  background-color: white;
  border: 1px solid var(--blue02);
  box-shadow: 2px 2px 2px 2px hsla(0, 0%, 0%, 0.3);
  list-style: none;
  top: 2.9rem;
  left: 0;
}

.subPage--header--menu--list--hidden {
  display: none;
}

.subPage--header--menu--list--visible {
  display: block;
}

.subPage--header--menu li {
  font-weight: bold;
  color: var(--blue01);
  transition: 0.3s;
}

.subPage--header--menu li:hover {
  background-color: var(--blue01);
  color: white;
}

.subPage--header--menu li a {
  display: block;
  padding: 1rem 2rem;
}


.subPage--content{
  padding: 5rem 7rem;
  padding-bottom: 15rem;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-start;
  border-bottom: 7px solid var(--blue01);
}

.subPage--content h2 {
  color: var(--blue01);
  font-size: 2rem;
}

.subPage--content > p {
  margin-top: 2rem;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  text-justify: inter-word;
}

.subPage--content ul {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subPage--content ul li {
  margin-left: 1.5rem;
}

.subPage--content ul p {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
  
  .subPage--header {
    padding-top: 6rem;
  }

}

@media screen and (max-width: 800px) {

  .subPage--content {
    padding: 5rem 2rem;
    padding-bottom: 10rem;
  }

  .subPage--content h2 {
    font-size: 1.7rem;
  }
  
  .subPage--header {
    padding: 2rem;
    padding-top: 6rem;
    flex-direction: column;
    gap: 2rem;
  }

}


/* Secteurs ------------------------------------------------------------------------------------------------------------- */

.secteurs--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14rem 3rem;
  color: black;
  gap: 5rem;
  margin: 0;
}

.secteurs--content h2 {
  text-align: center;
  color: white;
  font-size: 2rem;
}

.secteurs--grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.secteurs--grid--row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.secteurCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18rem;
  height: 18rem;
  background-color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.3rem;
  gap: 2rem;
  padding: 1rem;
  transition: 0.4s;
  cursor: pointer;
}

.secteurCard:hover {
  background-color: var(--blue01);
  color: white;
}

.secteurCard--round{
  width: 6rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsl(233, 100%, 7%);
  border: 7px solid hsl(233, 100%, 7%);
  border-radius: 100%;
  transition: 0.4s;
}

.secteurCard:hover .secteurCard--round {
  transform: scale(1.3);
  background-color: var(--blue02);
  border-color: var(--blue01);
}

.secteursSVG {
  fill: hsl(0, 0%, 80%);
}

.secteurCard:hover .secteursSVG {
  fill: var(--yellow);
}

.secteurCard:hover .service--button {
  font-weight: bold;
  border: 2px solid white;
}

.secteurCard:hover .service--button--yellowBar {
  background-color: var(--yellow);
}

/* media queries */

@media screen and (max-width: 1300px){

  .secteurs--content {
    padding: 12rem 3rem;
  }

  .secteurCard {
    height: 20rem;
    width: 15rem;
  }

}

@media screen and (max-width: 1100px){

  .secteurs--content {
    padding: 10rem 3rem;
  }

  .secteurs--grid--row {
    width: 45rem;
    flex-wrap: wrap;
  }

  .secteurCard {
    width: 18rem;
  }
}

@media screen and (max-width: 800px){

  .secteurs--grid--row {
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
  }

  .secteurs--content {
    padding: 7rem 1rem;
  }


  .secteurCard {
    width: 16rem;
  }

}

/* Devis -------------------------------------------------------------------------------------------------*/

.devis--content {
  padding: 14rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap : 10rem;
  align-items: center;
}

.devis--content h2 {
  text-align: center;
  font-size: 2rem;
}

.devis--infoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.infoSection--row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.infoSection--box {
  position: relative;
  width: 26rem;
  height: 13rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 5px 1px hsla(0, 0%, 0%, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1.5s;
}

.infoSection--box--hidden01 {
  opacity: 0;
  transform: translateX(-5rem);
}

.infoSection--box--hidden02 {
  opacity: 0;
  transform: translateX(5rem);
}

.infoSection--box--top {
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 100%;
  height: 4rem;
  background-color: var(--blue01);
  color: white;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: bold;

}

.infoSection--box--top .footerSVG {
  position: absolute;
  right: 1rem;
  transform: scale(0.9);
  fill: hsl(0, 0%, 80%)
}

.infoSection--box p {
  position: absolute;
  bottom: 3rem;
  width: 100%;
  color: hsl(0, 0%, 40%);
  padding: 0rem 2rem;
  font-size: 1.2rem;
  text-align: center;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 35.5rem;
}

.contactForm h2 {
  margin-bottom: 4rem;
}

.formColumns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.formCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.formInputText {
  margin-top: 0.5rem;
  display: block;
  width: 17rem;
  height: 3.5rem;
  border-radius: 0.3rem;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  border: none;
  box-shadow: 1px 1px 5px 1px hsla(0, 0%, 0%, 0.2);
}

.formInputText:focus {
  outline: none;
  box-shadow: 0px 0px 5px 2px hsla(233, 90%, 16%, 0.7);
}

.formInputTextArea {
  margin-top: 0.5rem;
  display: block;
  width: 35.5rem;
  height: 10rem;
  border-radius: 0.3rem;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  border: none;
  box-shadow: 1px 1px 5px 1px hsla(0, 0%, 0%, 0.2);
}

.formInputTextArea:focus {
  outline: none;
  box-shadow: 0px 0px 5px 2px hsla(233, 90%, 16%, 0.5);
}

.formCheckbox{
  width: 1rem;
  height: 1rem;
  margin-right: 0.7rem;
}

.formButton {
  background-color: var(--blue01);
  color: white;
  padding: 0.7rem 1.3rem;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  border: 2px solid var(--blue01);
  transition: 0.3s;
  cursor: pointer;
  background-color: var(--blue01);
  width: fit-content;
  margin-top: 0.7rem;
}

.formButton:hover {
  background-color: var(--blue02);
  border-color: white;
  font-weight: bold;
}

@media screen and (max-width: 1000px){
  
  .devis--content {
    padding: 10rem 5rem;
    gap : 7rem;
  }

  .infoSection--row {
    flex-direction: column;
  }
}

@media screen and (max-width: 800px){

  .devis--content h2 {
    font-size: 1.5rem;
  }

  .devis--content {
    padding: 7rem 1rem;
    gap : 7rem;
  }

  .devis--infoSection {
    width: 100%;
    gap: 3rem;
  }
  
  .infoSection--row {
    gap: 3rem;
  }
  
  .infoSection--box {
    width: 15rem;
    height: 11rem;
  }
  
  .infoSection--box--top {
    top: 1.2rem;
    right: 1.2rem;
    height: 3rem;
    font-size: 1.1rem;
  
  }
  
  .infoSection--box--top .footerSVG {
    transform: scale(0.7);
  }
  
  .infoSection--box p {
    bottom: 2rem;
    width: 100%;
    padding: 0rem 2rem;
    font-size: 1rem;
  }

  .contactForm {
    width: 100%;
  }

  .contactForm h2 {
    margin-bottom: 2.5rem;
  }

  .formColumns {
    flex-direction: column;
  }

  .formInputText {
    width: 16rem;
    height: 3rem;
  }

  .formInputTextArea {
    width: 16rem;
    height: 15rem;
  }

  .formCheckboxLabel {
    margin-top: 1.5rem;
    width: 15rem;
  }

}

/* LegalMentions ----------------------------------------------------------------------------------------- */

.legalMentions--header {
  background-color: var(--blue01);
  color: white;
  padding: 2rem 7rem;
}

.legalMentions--content {
  display: flex;
  flex-direction: column;
  padding: 7rem;
  gap: 5rem;
  line-height: 1.6;
  border-bottom: 7px solid var(--blue01);
}

.legalMentions--content--section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.legalMentions--content--section  p {
  text-align: justify;
}

.legalMentions--content h2 {
  color: var(--blue01);
  font-size: 2rem;
}

@media screen and (max-width: 800px) {

  .legalMentions--header {
    padding-top: 6rem;
  }
  
  .legalMentions--header h1 {
    font-size: 2rem;
    text-align: center;
  }

  .legalMentions--content {
    
    padding: 5rem 2rem;
    padding-bottom: 10rem;
  }

  .legalMentions--content h2 {
    font-size: 1.7rem;
  }


}

/* EspaceClient ----------------------------------------------------------------------------------------- */

.espaceClient--content {
  padding: 7rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.espaceClient--content h1 {
  font-size: 5rem;
}

.espaceClient--svg {
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.espaceClient--svg .svg {
  fill: var(--blue01);
  transform: scale(7);
}

@media screen and (max-width: 800px) {
  .espaceClient--content h1 {
    font-size: 2rem;
  }

  .espaceClient--svg {
    width: 12rem;
    height: 12rem;
  }
  
  .espaceClient--svg .svg {
    transform: scale(4);
  }
}


/* scrollbar --------------------------------------------------------------- */

@media screen and (min-width: 800px) {
  
  ::-webkit-scrollbar {
    width: 1rem;
  }
  
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: var(--blue01);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: var(--blue01);
    border-radius: 100vh;
    border: 2px solid white;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border: 2px solid var(--yellow);
  }
  
  
  @supports (scrollbar-color: black white){
    * {
      scrollbar-color: hsl(233, 90%, 16%);
      scrollbar-arrow-color: white;
      scrollbar-width: auto;
    }
  }
  
}

